
import { Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { useState, useEffect } from 'react';
import { TranslationProvider } from './contexts/TranslationContext';

import '@fortawesome/fontawesome-free/css/all.css';

// handlers
const ForwardToPost = lazy(()=>import ('./handlers/ForwardToPost'));
const ForwardToPostWithToken = lazy(()=>import ('./handlers/ForwardToPostWithToken'));
const ForwardToPut = lazy(()=>import ('./handlers/ForwardToPut'));
const ForwardToDelete = lazy(()=>import ('./handlers/ForwardToDelete'));
// components
const SideBar = lazy(() => import('./components/SideBar.jsx'));

// auth
const LoginPage = lazy(() => import('./pages/auth/LoginPage'));

// home
const Home = lazy(() => import('./pages/Home.jsx'));
const DashBoard = lazy(() => import('./pages/DashBoard.jsx'));

// courses
const CoursesHomePage = lazy(() => import('./pages/courses/CoursesHomePage.jsx'));
const CategoryDetailsPage = lazy(() => import('./pages/courses/CategoryDetailsPage.jsx'));
const AddCoursePage = lazy(() => import('./pages/courses/AddCoursePage.jsx'));
const AddLessonsPage = lazy(() => import('./pages/courses/AddLessonsPage.jsx'));

// students
const StudentsHomePage = lazy(() => import('./pages/students/StudentsHomePage.jsx'));
const StudentDetailsPage = lazy(() => import('./pages/students/StudentDetailsPage.jsx'));

//teachers
const TeachersHomePage = lazy(() => import('./pages/teachers/TeachersHomePage.jsx'));
const TeacherDetailsPage = lazy(() => import('./pages/teachers/TeacherDetailsPage.jsx'));


// chats
const ChatHomePage = lazy(() => import('./pages/chats/ChatHomePage.jsx'));
const ChatDetailsPage = lazy(() => import('./pages/chats/ChatDetailsPage.jsx'));

// projects
const ProjectsHomePage = lazy(() => import('./pages/projects/ProjectsHomePage.jsx'));
const ProjectDetailsPage = lazy(() => import('./pages/projects/ProjectDetailsPage.jsx'));

//enrollment requests
const EnrollmentRequestsHomePage = lazy(() => import('./pages/enrollmentRequests/EnrollmentRequestsHomePage.jsx'));
const EnrollmentRequestsDetailsPage = lazy(() => import('./pages/enrollmentRequests/EnrollmentRequestsDetailsPage.jsx'));

// notifications
const NotificationsHomePage = lazy(() => import('./pages/notifications/NotificationsHomePage.jsx'));


function useTokenChangeEffect() {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
      useEffect(() => {
        // Save the token to localStorage when it changes
        localStorage.setItem('token', token);
        // Trigger a re-render whenever the token changes
        setToken(token);
      }, [token]);

      useEffect(() => {
        // Listen for changes to the localStorage from other browsing contexts
        const handleStorageChange = (e) => {
          if (e.key === 'token') {
            setToken(e.newValue);
          }
        };

        window.addEventListener('storage', handleStorageChange);

      }, []);

  return [token, setToken];

}


function App() {
  const [token, setToken] = useTokenChangeEffect();
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  useEffect(() => {
    if(token !== '' && token !== null){
      setIsLoggedIn(true);
    }else{
      setIsLoggedIn(false);
    }
  }, [token]);
 return (
    <>
    {/* <NavBar auth={isLoggedIn} token={token}/> */}
      <TranslationProvider>
        <Suspense fallback={<div className="container">Loading...</div>}>
          {isLoggedIn && <SideBar setIsLoggedIn={setIsLoggedIn}/> }
          <Routes>
            <Route path="/" element={<Home  isLoggedIn={isLoggedIn}/>} />
              <Route path='/login' element={<LoginPage setIsLoggedIn={setIsLoggedIn}/>} />
              <Route path="/forward-to-post" element={<ForwardToPost />} />
              <Route path="/forward-to-post-with-token" element={<ForwardToPostWithToken />} />
              <Route path="/forward-to-put" element={<ForwardToPut />} />
              <Route path="/forward-to-delete" element={<ForwardToDelete />} />
              <Route path="/dashboard" element={<DashBoard />} />
              {/* courses */}
              <Route path="/courses" element={<CoursesHomePage />} />
              <Route path="/courses/category-details" element={<CategoryDetailsPage />} />
              <Route path="/courses/add" element={<AddCoursePage />} />
              <Route path="/courses/add-lessons" element={<AddLessonsPage />} />
              {/* students */}
              <Route path="/students" element={<StudentsHomePage />} />
              <Route path="/students/student-details" element={<StudentDetailsPage />} />
              {/* teachers */}
              <Route path="/teachers" element={<TeachersHomePage />} />
              <Route path="/teachers/teacher-details" element={<TeacherDetailsPage />} />
              {/* chats */}
              <Route path="/chats" element={<ChatHomePage />} />
              <Route path="/chats/chat-details/:chatId" element={<ChatDetailsPage />} />
              {/* projects */}
              <Route path="/requests" element={<ProjectsHomePage />} />
              <Route path="/requests/request-details" element={<ProjectDetailsPage />} />
              {/* enrollment requests */}
              <Route path="/enrollment-requests" element={<EnrollmentRequestsHomePage />} />
              <Route path="/enrollment-requests/view" element={<EnrollmentRequestsDetailsPage />} />
              {/* chats */}
              <Route path="/chats" element={<ChatHomePage />} />
              <Route path="/chats/chat-details" element={<ChatDetailsPage />} />
              {/* notifications */}
              <Route path="/notifications" element={<NotificationsHomePage />} />

          </Routes>
        </Suspense>
      </TranslationProvider>

    </>
  );
}

export default App;
