import React, { createContext, useContext, useState } from 'react';

const translations = {
  en: require('../locales/en/common.json'),
  ar: require('../locales/ar/common.json'),
};

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('ar');
  const [t, setT] = useState(translations[language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
    setT(translations[lng]);
    // document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <TranslationContext.Provider value={{ t, changeLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
